import Link from "next/link";
import { MouseEventHandler } from "react";
import styled, { keyframes } from "styled-components";

const animationShine = keyframes`
100% {
    transform: rotateZ(60deg) translate(1em, -9em);
  }
`;

const StyledButton = styled.button<{
  "data-primary"?: boolean;
  "data-buddy"?: string;
  "data-no-sparkle"?: boolean;
  $accent?: string;
}>`
  display: inline-flex;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 700;
  transition: 0.2s ease;
  border: none;

  background: var(--background-color, var(--raised-background-color));
  color: var(--text-color, #dedede);

  background: ${(props) => props["data-primary"] && "var(--primary-color, #7551cb)"};
  background: ${(props) =>
    props["data-buddy"] && "var(--buddy-color, var(--raised-background-color-three))"};

  box-shadow: ${(props) =>
    `0px 0px 0px 1px ${props.$accent ? props.$accent : "var(--shadow-color, #5630b2)"},
    0 4px 0px 1px ${props.$accent ? props.$accent : "var(--shadow-color, #5630b2)"};`}

  text-decoration: none;

  overflow: hidden;
  position: relative;

  &:hover {
    text-decoration: none;
    transform: translate(0, -2px);
    box-shadow:
      0px 0px 0px 1px
        ${(props) =>
          props["data-primary"]
            ? "#5630b2"
            : props["data-buddy"]
              ? "#7551cb"
              : "var(--text-color)"},
      0 6px 0px 1px
        ${(props) =>
          props["data-primary"]
            ? "#5630b2"
            : props["data-buddy"]
              ? "#7551cb"
              : "var(--text-color)"};
  }
  &:active {
    box-shadow:
      0px 0px 0px 1px
        ${(props) =>
          props["data-primary"]
            ? "#5630b2"
            : props["data-buddy"]
              ? "#7551cb"
              : "var(--text-color)"},
      0px 0px 0px 1px
        ${(props) =>
          props["data-primary"]
            ? "#5630b2"
            : props["data-buddy"]
              ? "#7551cb"
              : "var(--text-color)"};
    transform: translate(0, 4px);
    text-decoration: none;
  }
  &:after {
    opacity: ${(props) => (props["data-no-sparkle"] ? "0" : "100")};
    content: "";
    position: absolute;
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%;
    background: linear-gradient(
      to bottom,
      rgba(117, 81, 203, 0),
      rgba(164, 140, 221, 0.3) 50%,
      rgba(117, 81, 203, 0)
    );
    transform: rotateZ(60deg) translate(-5em, 7.5em);
  }
  &:hover::after,
  &:focus::after {
    animation: ${animationShine} 1s forwards;
  }
`;

const StyledWrapper = styled.div<{
  $alignSelf: React.CSSProperties["alignSelf"];
  $disabled?: boolean;
}>`
  position: relative;
  align-self: ${(props) => props.$alignSelf || "flex-start"};
  transition: transform 0.2s ease;

  ${(props) =>
    props.$disabled &&
    `
      opacity: 0.5;
      pointer-events: none;
    `}
`;

interface CommonLinkProps {
  "data-primary"?: boolean;
  "data-buddy"?: string;
  "data-no-sparkle"?: boolean;
}
const StyledLink = styled.a<CommonLinkProps>`
  display: inline-flex;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 700;
  transition: 0.2s ease;

  background: var(--background-color, var(--raised-background-color));
  color: var(--text-color, #dedede);

  background: ${(props) => props["data-primary"] && "var(--primary-color, #7551cb)"};
  background: ${(props) =>
    props["data-buddy"] && "var(--buddy-color, var(--raised-background-color-three))"};

  box-shadow:
    0px 0px 0px 1px var(--shadow-color, #5630b2),
    0 4px 0px 1px var(--shadow-color, #5630b2);

  text-decoration: none;

  position: relative;
  overflow: hidden;

  &:hover {
    text-decoration: none;
    transform: translate(0, -2px);
    box-shadow:
      0px 0px 0px 1px
        ${(props) =>
          props["data-primary"]
            ? "#5630b2"
            : props["data-buddy"]
              ? "#7551cb"
              : "var(--text-color)"},
      0 6px 0px 1px
        ${(props) =>
          props["data-primary"]
            ? "#5630b2"
            : props["data-buddy"]
              ? "#7551cb"
              : "var(--text-color)"};
  }

  &:active {
    box-shadow:
      0px 0px 0px 1px
        ${(props) =>
          props["data-primary"]
            ? "#5630b2"
            : props["data-buddy"]
              ? "#7551cb"
              : "var(--text-color)"},
      0px 0px 0px 1px
        ${(props) =>
          props["data-primary"]
            ? "#5630b2"
            : props["data-buddy"]
              ? "#7551cb"
              : "var(--text-color)"};
    transform: translate(0, 4px);
    text-decoration: none;
  }
  &:after {
    content: "";
    position: absolute;
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%;
    background: linear-gradient(
      to bottom,
      rgba(117, 81, 203, 0),
      rgba(164, 140, 221, 0.3) 50%,
      rgba(117, 81, 203, 0)
    );
    transform: rotateZ(60deg) translate(-5em, 7.5em);
  }
  &:hover::after,
  &:focus::after {
    animation: ${animationShine} 1s forwards;
  }
`;
const StyledOutLink = styled.a<CommonLinkProps>`
  display: inline-flex;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 700;
  transition: 0.2s ease;

  background: var(--background-color, var(--raised-background-color));
  color: var(--text-color, #dedede);

  background: ${(props) => props["data-primary"] && "var(--primary-color, #7551cb)"};
  background: ${(props) =>
    props["data-buddy"] && "var(--buddy-color, var(--raised-background-color-three))"};

  box-shadow:
    0px 0px 0px 1px
      ${(props) =>
        props["data-primary"] ? "#5630b2" : props["data-buddy"] ? "#7551cb" : "var(--text-color)"},
    0 4px 0px 1px
      ${(props) =>
        props["data-primary"] ? "#5630b2" : props["data-buddy"] ? "#7551cb" : "var(--text-color)"};

  text-decoration: none;
  overflow: hidden;
  position: relative;

  &:hover {
    text-decoration: none;
    transform: translate(0, -2px);
    box-shadow:
      0px 0px 0px 1px
        ${(props) =>
          props["data-primary"]
            ? "#5630b2"
            : props["data-buddy"]
              ? "#7551cb"
              : "var(--text-color)"},
      0 6px 0px 1px
        ${(props) =>
          props["data-primary"]
            ? "#5630b2"
            : props["data-buddy"]
              ? "#7551cb"
              : "var(--text-color)"};
  }

  &:active {
    box-shadow:
      0px 0px 0px 1px
        ${(props) =>
          props["data-primary"]
            ? "#5630b2"
            : props["data-buddy"]
              ? "#7551cb"
              : "var(--text-color)"},
      0px 0px 0px 1px
        ${(props) =>
          props["data-primary"]
            ? "#5630b2"
            : props["data-buddy"]
              ? "#7551cb"
              : "var(--text-color)"};
    transform: translate(0, 4px);
    text-decoration: none;
  }
  &:after {
    content: "";
    position: absolute;
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%;
    background: linear-gradient(
      to bottom,
      rgba(117, 81, 203, 0),
      rgba(164, 140, 221, 0.3) 50%,
      rgba(117, 81, 203, 0)
    );
    transform: rotateZ(60deg) translate(-5em, 7.5em);
  }
  &:hover::after,
  &:focus::after {
    animation: ${animationShine} 1s forwards;
  }
`;

export interface BaseButtonProps {
  alignSelf?: React.CSSProperties["alignSelf"];
  buttonBuddy?: string;
  buttonPrimary?: boolean;
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  noSparkle?: boolean;
  style?: React.CSSProperties;
  onClick?: MouseEventHandler<Element>;
  trackClick?: boolean;
  type?: "button" | "submit" | "reset";
  wrapperStyle?: React.CSSProperties;
  wrapperClass?: string;
  dataAttr?: string;
  $accent?: string;
}

type ToLinkButtonProps = BaseButtonProps &
  React.HTMLProps<HTMLAnchorElement> & {
    to: string;
  };

type HREFLinkButtonProps = BaseButtonProps &
  React.HTMLProps<HTMLAnchorElement> & {
    href: string;
  };

type ActualButtonProps = BaseButtonProps & React.HTMLProps<HTMLButtonElement>;

export type ButtonProps = ActualButtonProps | HREFLinkButtonProps | ToLinkButtonProps;

const isHrefLinkButton = (props: ButtonProps): props is HREFLinkButtonProps => "href" in props;

const isToLinkButton = (props: ButtonProps): props is ToLinkButtonProps => "to" in props;

const Button = (props: ActualButtonProps) => {
  const {
    alignSelf,
    buttonBuddy,
    buttonPrimary,
    children,
    className,
    disabled,
    onClick,
    trackClick = false,
    type,
    noSparkle,
    wrapperStyle,
    wrapperClass,
    dataAttr,
    ...rest
  } = props;
  return (
    <StyledWrapper
      style={wrapperStyle}
      className={wrapperClass}
      $alignSelf={alignSelf}
      $disabled={disabled}>
      <StyledButton
        {...rest}
        data-no-sparkle={noSparkle}
        data-primary={buttonPrimary}
        className={className}
        onClick={(e) => props.onClick?.(e)}
        data-buddy={buttonBuddy}
        type={type}
        data-attr={dataAttr || ""}>
        {children}
      </StyledButton>
    </StyledWrapper>
  );
};

const ToLinkButton = (props: ToLinkButtonProps) => {
  const {
    alignSelf,
    buttonBuddy,
    buttonPrimary,
    children,
    className,
    disabled,
    onClick,
    trackClick = false,
    type,
    to,
    wrapperStyle,
    noSparkle,
    wrapperClass,
    dataAttr,
    ...rest
  } = props;

  return (
    <StyledWrapper
      style={wrapperStyle}
      className={wrapperClass}
      $alignSelf={alignSelf}
      $disabled={disabled}>
      <Link href={to} passHref legacyBehavior>
        <StyledLink
          data-no-sparkle={noSparkle}
          data-primary={buttonPrimary}
          className={className}
          data-buddy={buttonBuddy}
          onClick={onClick}
          data-attr={dataAttr || ""}
          {...rest}>
          {children}
        </StyledLink>
      </Link>
    </StyledWrapper>
  );
};

const HREFLinkButton = (props: HREFLinkButtonProps) => {
  const {
    alignSelf,
    buttonBuddy,
    buttonPrimary,
    children,
    className,
    disabled,
    onClick,
    trackClick = false,
    type,
    href,
    wrapperStyle,
    wrapperClass,
    dataAttr,
    ...rest
  } = props;

  return (
    <StyledWrapper
      style={wrapperStyle}
      className={wrapperClass}
      $alignSelf={alignSelf}
      $disabled={disabled}>
      <StyledOutLink
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        data-primary={buttonPrimary}
        className={className}
        data-buddy={buttonBuddy}
        data-attr={dataAttr || ""}
        {...rest}
        onClick={(e) => {
          if (trackClick) {
            // clickUrlEvent(href);
          }
          if (onClick) {
            onClick(e);
          }
        }}>
        {children}
      </StyledOutLink>
    </StyledWrapper>
  );
};

const MasterButton = (props: ButtonProps) => {
  if (isHrefLinkButton(props)) {
    return <HREFLinkButton {...props} />;
  }

  if (isToLinkButton(props)) {
    return <ToLinkButton {...props} />;
  }

  return <Button {...props} />;
};

export default MasterButton;
