import { CookieConsentBanner } from "./cookieConsentBanner";
import { CookiePreferencesModal } from "./cookiePreferencesModal";
import { CookieWidget } from "./cookieWidget";

export const CookieConsent = () => {
  return (
    <>
      <CookieConsentBanner />
      <CookieWidget />
      <CookiePreferencesModal />
    </>
  );
};
