import { useRef } from "react";
import { renderIf } from "../../util";
import Button from "../general/buttonv2";
import ClientOnlyPortal from "../general/clientOnlyPortal";
import Switch from "../general/switch";
import { CookieType, useCookieConsent } from "./cookieConsentProvider";
import { useCollapse } from "react-collapsed";
import { Portal, Dialog } from "@ark-ui/react";

const CollapsibleInfoPanel = ({
  title,
  prefKey,
  children,
  defaultToggle,
}: {
  title: string;
  children: React.ReactNode;
  prefKey?: CookieType;
  defaultToggle?: boolean;
}) => {
  const { updatePrefs, preferences } = useCookieConsent();
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
    defaultExpanded: false,
  });

  const icon = isExpanded ? "ic--baseline-minus" : "ic--baseline-plus";

  return (
    <div className="rounded-lg bg-brand-duskslate my-3">
      <div className="text-base">
        <div className="flex flex-row items-center" {...getToggleProps()}>
          <span className={`px-8 icon-[${icon}] h-[18px] w-[18px]`} />
          <h1 className="text-lg p-4 pl-0 font-semibold m-0">{title}</h1>
          {renderIf(prefKey, (key) => (
            <div className="ml-auto pr-4">
              <Switch
                defaultValue={defaultToggle}
                id={`toggle-${title}`}
                onToggle={(toggled) => updatePrefs(key, toggled)}
                value={preferences[key]}
              />
            </div>
          ))}
        </div>
        <div {...getCollapseProps()}>
          <div className="px-16 pb-4">{children}</div>
        </div>
      </div>
    </div>
  );
};

export const CookiePreferencesModal = () => {
  const { essentialOnly, showModal, setShowModal, setShowBanner, savePrefs, hasSetConsent } =
    useCookieConsent();
  const modalContainer = useRef<HTMLDivElement>(null);

  const save = () => {
    savePrefs();
    setShowModal(false);
    setShowBanner(false);
  };

  const saveEssential = () => {
    essentialOnly();
    setShowModal(false);
    setShowBanner(false);
  };

  console.log(hasSetConsent);
  return (
    <Dialog.Root
      open={showModal}
      closeOnEscape={hasSetConsent}
      closeOnInteractOutside={hasSetConsent}
      onOpenChange={({ open }) => setShowModal(open)}>
      <Portal>
        <Dialog.Backdrop
          className={
            "bg-black bg-opacity-50 top-0 left-0 fixed w-full h-full z-[101] pointer-events-none"
          }
        />
        <Dialog.Positioner className="fixed top-0 left-0 w-full h-screen justify-center items-center flex z-[105]">
          <Dialog.Content
            className="bg-brand-slate p-5 md:w-1/2 w-4/5 shadow-2xl border-brand-baby-blue border-t-[18px] px-[30px] pb-[30px] rounded-lg relative"
            onClick={(e) => e.stopPropagation()}>
            {hasSetConsent ? (
              <Dialog.CloseTrigger className="absolute top-[-30px] right-[15px] w-[40px] h-[40px] bg-brand-slate border-brand-baby-blue border-[4px] rounded-lg flex items-center justify-center hover:cursor-pointer">
                <span className={`icon-[material-symbols--close] w-[26px] h-[26px] bg-white`} />
              </Dialog.CloseTrigger>
            ) : null}
            <h1 className="text-lg m-0">Cookie preferences</h1>
            <p className="m-0">You can select your cookie preferences here.</p>
            <CollapsibleInfoPanel title={"Functional Cookies (Necessary)"}>
              <p className="m-0">
                These cookies are essential for the website to function and cannot be switched off
                in our systems.
              </p>
              Examples: Login authentication, shopping cart functionality.
            </CollapsibleInfoPanel>
            <CollapsibleInfoPanel prefKey="analytics" title={"Analytics Cookies"}>
              <p className="m-0">
                These cookies help us measure and improve the performance of our site by collecting
                anonymous data.
              </p>
              Examples: Remembering language preferences.
            </CollapsibleInfoPanel>
            <CollapsibleInfoPanel prefKey="marketing" title={"Marketing Cookies"}>
              <p className="m-0">
                These cookies are used to deliver personalized ads and track your browsing activity
                across websites.
              </p>
              Examples: Tracking page load times
            </CollapsibleInfoPanel>
            <CollapsibleInfoPanel prefKey="preferences" title={"Preferences Cookies"}>
              <p className="m-0">
                These cookies remember your settings, like language or layout, to provide a
                personalized and seamless experience.
              </p>
              Examples: Remembering theme preferences.
            </CollapsibleInfoPanel>
            <div className="flex mt-4">
              <Button noSparkle={true} onClick={saveEssential}>
                Essential only
              </Button>
              <Button noSparkle={true} wrapperClass="ml-auto" onClick={save} buttonPrimary={true}>
                Save
              </Button>
            </div>
          </Dialog.Content>
        </Dialog.Positioner>
      </Portal>
    </Dialog.Root>
  );
};
