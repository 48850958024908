import { datadogRum } from "@datadog/browser-rum";
import Button from "components/general/buttonv2";
import ThemeChanger from "components/services/themeChanger";
import asyncComponent from "components/split/asyncComponent";
import { navLinksArray } from "data/variables";
import Link from "next/link";
import { withRouter } from "next/router";
import { usePostHog } from "posthog-js/react";
import { useEffect, useMemo } from "react";
import { useUser } from "src/context/user";
import styled from "styled-components";
import { gtag_set_user_id } from "../../lib/ga";
const Logo = asyncComponent(() => import("components/general/logo"));

const StyledWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99;
  padding: 1.5rem 2rem;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 1140px) {
    display: none;
  }
  font-family: "Open Sans", sans-serif;
  color: var(--text-color);
`;
const StyledNavLink = styled.a`
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  flex: 0 0 180px;
`;
const StyledSubmenuLink = styled.a`
  position: relative;
  cursor: pointer;
  color: var(--text-color);
  white-space: nowrap;
  &:after {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: skew(-5deg);
    content: "";
    border: 1px solid #888;
    left: 0;
    top: 0;
    display: none;
  }
  &:hover {
    text-decoration: none;
    &:after {
      display: block;
    }
  }
`;
const StyledContainer = styled.nav`
  max-width: 1280px;
  align-items: center;
  justify-content: space-between;
  display: flex;
  width: 100%;
  ul {
    display: flex;
    list-style-type: none;
    align-items: center;
    gap: 2rem;
    > * {
      display: flex;
    }
  }
  ${StyledNavLink} {
    &:after {
      content: none;
    }
  }
  a {
    color: var(--text-color);
    padding: 0.5rem 0.5rem;
    position: relative;
    text-decoration: none;
  }
  ${StyledSubmenuLink} {
    &:after {
      transform: none;
    }
  }
`;
const StyledLink = styled.a`
  white-space: nowrap;
  font-weight: 500;
  &:after {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: skew(-5deg);
    content: "";
    border: 1px solid #888;
    left: 0;
    top: 0;
    display: ${(props) => (props["data-active"] ? "block" : "none")};
  }
  &:hover {
    text-decoration: none;
    &:after {
      display: block;
    }
  }
`;
const SPACING_BETWEEN = "0 .5rem";
const StyledSubmenuContainer = styled.div`
  position: absolute;
  top: 100%;
  background-color: var(--raised-background-color);
  color: var(--text-color);
  flex-direction: column;
  opacity: 0;
  > a:hover {
    cursor: pointer;
  }
`;
const StyledSubmenuWrapper = styled.div`
  color: var(--text-color);
  height: 100%;
  display: none;
  position: relative;
  align-items: center;
  ${StyledSubmenuContainer} {
    display: none;
  }
  &:hover,
  &:focus-within {
    ${StyledSubmenuContainer} {
      display: flex;
      opacity: 1;
    }
  }
`;
const StyledSubmenuMainLink = styled.button`
  font-size: var(--size-large);
  cursor: pointer;
  box-sizing: border-box;
  transition: 0.2s ease;
  white-space: nowrap;
  text-decoration: none;
  transition: 0.2s ease;
  text-align: center;
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  font-family: var(--font-family-standard);
  svg {
    height: 10px;
    width: 10px;
    margin-left: 6px;
  }

  color: var(--text-color);
  fill: var(--text-color);
  padding: 0.5rem 1rem;
  position: relative;
  text-decoration: none;
  &:after {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: skew(-5deg);
    content: "";
    border: 1px solid #888;
    left: 0;
    top: 0;
    display: none;
  }
  &:hover {
    cursor: pointer;
    text-decoration: none;
    &:after {
      display: block;
    }
  }
`;

const StyledAccent = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 5px;
`;
const Submenu = ({ dark }) => {
  return (
    <StyledSubmenuWrapper>
      <StyledSubmenuMainLink>
        <span>Services</span>&nbsp;
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
        </svg>
      </StyledSubmenuMainLink>
      <StyledSubmenuContainer data-dark={dark}>
        <Link passHref href="/services/discord" legacyBehavior>
          <StyledSubmenuLink>
            <StyledAccent style={{ background: "#5865f2" }} />
            <div style={{ paddingLeft: "5px" }}>Discord</div>
          </StyledSubmenuLink>
        </Link>
        <Link passHref href="/services/twitter" legacyBehavior>
          <StyledSubmenuLink>
            <StyledAccent style={{ background: "#189eed" }} />
            <div style={{ paddingLeft: "5px" }}>Twitter</div>
          </StyledSubmenuLink>
        </Link>
        <Link passHref href="/services/facebook" legacyBehavior>
          <StyledSubmenuLink>
            <StyledAccent style={{ background: "#1f73eb" }} />
            <div style={{ paddingLeft: "5px" }}>Facebook</div>
          </StyledSubmenuLink>
        </Link>
        <Link passHref href="/services/reddit" legacyBehavior>
          <StyledSubmenuLink>
            <StyledAccent style={{ background: "#ff4500" }} />
            <div style={{ paddingLeft: "5px" }}>Reddit</div>
          </StyledSubmenuLink>
        </Link>
        <Link passHref href="/services" legacyBehavior>
          <StyledSubmenuLink>
            <StyledAccent style={{ background: "#5865f2" }} />
            <div style={{ paddingLeft: "5px" }}>All (30+ more)</div>
          </StyledSubmenuLink>
        </Link>
      </StyledSubmenuContainer>
    </StyledSubmenuWrapper>
  );
};

const Navbar = ({ dark, router }) => {
  const posthog = usePostHog();
  const { userState } = useUser();

  useEffect(() => {
    if (userState.user) {
      posthog?.identify(userState.user.id, {
        email: userState.user.email,
      });

      datadogRum.setUser({
        id: userState.user.id,
        email: userState.user.email,
        hasPremium: !!userState.user.subscription?.isActive,
      });

      gtag_set_user_id(userState.user.id);
    }
  }, [posthog, userState, userState.user?.id]);

  const navLinks = useMemo(() => {
    const isLoggedIn = userState.initialized && userState.user;
    const concat = isLoggedIn
      ? [{ label: "Account", link: "/account" }]
      : [{ label: "Log in", link: "/login" }];

    return [...navLinksArray, ...concat];
  }, [userState.initialized, userState.user]);

  return (
    <StyledWrapper data-dark={dark}>
      <StyledContainer data-dark={dark}>
        <Link href="/" passHref legacyBehavior>
          <StyledNavLink>
            <Logo size="h-[3.5rem]" />
          </StyledNavLink>
        </Link>
        <ul style={{ padding: 0 }}>
          {navLinks.map((value) => {
            if (value.label === "Services") {
              return <Submenu key="services" dark={dark} />;
            }
            return (
              <li key={value.label}>
                <Link href={value.link} passHref legacyBehavior>
                  <StyledLink
                    data-active={router?.pathname === value.link}
                    data-action-name={`Navbar: ${value.label}`}>
                    {value.label}
                  </StyledLink>
                </Link>
              </li>
            );
          })}
          <Button to="/download" buttonBuddy={true}>
            Download
          </Button>
          <ThemeChanger />
          {/*
          <StyledPremium>
            <Link href="/download" passHref legacyBehavior>
              <StyledLink data-action-name="Navbar: Download">Download</StyledLink>
            </Link>
          </StyledPremium>
          */}
          {/*
          <StyledPremium>
            <Link href="/login" passHref legacyBehavior>
              <StyledLink
                data-action-name={userState.user ? "Navbar: Manage Account" : "Navbar: Premium"}
              >
                {userState.user ? "Manage account" : "Premium"}
              </StyledLink>
            </Link>
          </StyledPremium>
          */}
        </ul>
      </StyledContainer>
    </StyledWrapper>
  );
};

export default withRouter(Navbar);
