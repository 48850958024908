import React from "react";
import Button from "../general/buttonv2";
import { Bold } from "../general/util";
import { useCookieConsent } from "./cookieConsentProvider";
import Link from "next/link";
import { CatImage } from "./cookieDeclaration";

export const CookieConsentBanner = () => {
  const { savePrefs, setShowBanner, showBanner, setShowModal, hasSetConsent } = useCookieConsent();

  if (!showBanner) {
    return null;
  }

  return (
    <>
      <div
        className={`fixed w-full flex flex-col sm:flex-row items-center justify-between z-50 bottom-[20px] md:bottom-[40px] z-[101]`}>
        <div className="w-full xl:w-2/6 md:w-3/6 sm:w-5/6 relative">
          <CatImage width={150} height={150} wrapperClass="absolute top-[-110px] right-[20px]" />
          <div className="w-[90%] flex m-auto border-brand-baby-blue p-[30px] border-t-[18px] pt-[20px] flex-col bg-brand-slate shadow-lg rounded-lg m-auto relative">
            {hasSetConsent ? (
              <div
                onClick={() => setShowBanner(false)}
                className="absolute top-[-30px] right-[15px] w-[40px] h-[40px] bg-brand-slate border-brand-baby-blue border-[4px] rounded-lg flex items-center justify-center hover:cursor-pointer">
                <span className={`icon-[material-symbols--close] w-[26px] h-[26px] bg-white`} />
              </div>
            ) : null}
            <p className="text-sm sm:text-base mb-0 sm:mb-0">
              We use <Link href="https://redact.dev/cookies">cookies</Link> to give you the best
              online experience. Strictly necessary cookies are <Bold>on</Bold> by default.
              Additional cookies are off by default. See our privacy policy{" "}
              <Link href="https://redact.dev/privacy">here</Link>
            </p>
            <div className="flex w-full mt-3 md:mb-0 [&_button]:block">
              <Button noSparkle={true} onClick={() => setShowModal(true)} $accent="#eee">
                Manage preferences
              </Button>
              <Button
                noSparkle={true}
                wrapperClass="ml-auto"
                buttonPrimary={true}
                onClick={() => savePrefs({ marketing: true, analytics: true, preferences: true })}>
                Accept all
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
