import ClientPortal from "../general/clientPortal";
import { useCookieConsent } from "./cookieConsentProvider";

export const CookieWidget = () => {
  const { setShowBanner, loaded } = useCookieConsent();

  if (!loaded) {
    return null;
  }

  return (
    <ClientPortal>
      <div
        onClick={() => setShowBanner(true)}
        className="fixed bottom-[10px] left-[8px] w-[35px] h-[35px] rounded-full flex items-center justify-center hover:cursor-pointer z-50">
        <span className="icon-[mdi--cookie] w-[32px] h-[32px]"></span>
      </div>
    </ClientPortal>
  );
};
