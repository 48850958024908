import Link from 'next/link'
import Image from "next/image";

interface MdxLayoutInterface {
    children: React.ReactNode 
}

function MdxLayout({ children }: MdxLayoutInterface) {
    // Create any shared layout or styles here
    return (
        <div className="flex flex-col w-full items-center ">
        <div className="flex mt-20 -ml-44 sm:-ml-[500px] md:-ml-[750px] lg:-ml-[1100px]">
            <Link className="px-3 mt-2" href="/ai-optout-list">
                <Image
                    src={require('public/images/aiOptOut/common/redact-back-arrow.svg')}
                    alt={'redact-back-arrow'}
                    className=""
                    width={9}
                    priority
                />
            </Link>
            <Link href={"/ai-optout-list"} className="text-white text-xl font-semibold">Back to optout list</Link>
        </div>
        <div className="flex flex-row">
            <div className="h-[145px] bg-blue-500 w-3 mt-10 sm:mt-7" />
            <div className="
                prose
                sm:min-w-[1234px]
                mt-5
                text-white 
                prose-strong:text-white 
                prose-headings:mt-8  
                prose-headings:font-bold 
                prose-headings:text-white 
                sm:prose-h1:text-[45px] 
                sm:prose-h1:w-[700px]
                prose-h2:text-4xl 
                prose-h3:text-3xl 
                prose-h4:text-2xl 
                prose-h5:text-xl 
                prose-h6:text-lg
                prose-a:text-white
                ">
                {children}
            </div>
        </div>
        </div>
    )
  }

  export default MdxLayout