import React, { useEffect, useState } from "react";
import * as RadixSwitch from "@radix-ui/react-switch";

interface SwitchProps {
  id: string;
  onToggle?: (toggled: boolean) => void;
  defaultValue?: boolean;
  value?: boolean;
  disabled?: boolean;
}

const Switch = ({
  id,
  onToggle,
  defaultValue = false,
  value,
  disabled,
}: SwitchProps) => {
  const [on, setOn] = useState(value ?? defaultValue ?? false);
  const [textClass, setTextClass] = useState("opacity-100");

  useEffect(() => {
    if (typeof value === "undefined") {
      return;
    }
    setOn(value);
  }, [value]);

  const onCheckedChange = (val: boolean) => {
    setOn(val);
    onToggle?.(val);

    setTextClass("opacity-0");

    setTimeout(() => {
      setTextClass("opacity-100");
    }, 50);
  };

  const bgClass = () => {
    if (disabled) {
      return "bg-black";
    }

    return on ? `bg-brand-blue` : `bg-neutral-500`;
  };

  return (
    <div
      className="flex align-center relative"
      // dont bubble events up
      onClick={(e) => e.stopPropagation()}
    >
      <RadixSwitch.Root
        defaultChecked={defaultValue}
        disabled={disabled}
        onCheckedChange={onCheckedChange}
        checked={value}
        className={`flex overflow-hidden content-center w-[63px] h-[25px] ${bgClass()} ${disabled ? `cursor-not-allowed` : ""} rounded-full relative p-[3px] border-0`}
        id={id}
      >
        <RadixSwitch.Thumb className="top-[4px] absolute w-[17px] h-[17px] bg-white rounded-full translate-x-[2px] data-[state=checked]:translate-x-[39px] transition duration-200" />
        <span
          className={`absolute ${on ? `left-[7px]` : `right-[9px]`} font-bold text-sm uppercase ${textClass}`}
        >
          {on ? "on" : "off"}
        </span>
      </RadixSwitch.Root>
    </div>
  );
};

export default Switch;
