export const CodeSnippet = ({ code }: { code: string }) => {
  return (
    <div className={`flex`}>
      <span
        title={code}
        className="bg-gray-200 dark:bg-gray-800 text-gray-800 dark:text-gray-200 px-1.5 py-0.5 rounded text-sm font-mono text-ellipsis overflow-hidden whitespace-nowrap"
      >
        {code}
      </span>
    </div>
  );
};
